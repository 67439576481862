define(['app'], function(app) {

  const productInstalmentPayment = function() {
    const component = {};

    const _config = {
      selectors: {
        component: '[data-component=productInstalmentPayment]'
      },
      subscribeChannels: {
        instalmentInfo: 'productInstalmentPayment/newInstalmentInfo'
      }
    };

    const _init = function (element, userDriven) {
      component.element = element;

      if (!userDriven) {
        app.subscribe(component.config.subscribeChannels.instalmentInfo, component.getNewInstalmentInfo);
      }

      return component;
    };

    const _getNewInstalmentInfo = function(productId) {
      let url = '/' + productId + '.productInstalmentPayment';

      app.ajax.get({
        url: url,
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    const _successHandler = function(response) {
      let parent = component.element.parentNode;
      parent.innerHTML = response;
      let element = parent.querySelector('component.config.selectors.component');
      component.init(element, true);
    };

    const _errorHandler = function() {
      console.error('ERROR: Could not retrieve new instalment payment info');
    };

    component.init = _init;
    component.config = _config;
    component.getNewInstalmentInfo = _getNewInstalmentInfo;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;

    return component;
  };

  return productInstalmentPayment;
});
